// @include media-breakpoint-up(sm) {
// }
// @include media-breakpoint-up(md) {
// }
// @include media-breakpoint-up(lg) {
// }
// @include media-breakpoint-up(xl) {
// }


body {
	overflow-y: scroll;
	overflow-x: hidden;
}

h1, h2, h3, h4, h5 {
	font-weight: 300;
	line-height: 1.2;
}

.serif {
	font-family: $font-family-serif;
}

.color--brand {
	color: $brand-primary;
}

.display--block {
	display: block;
}

strong {
	font-weight: 400;
}

.content-block h1 {
	font-size: 50px;
	margin: 0;
}

.content-block h2 {
	transition: opacity $transition-time $transition-type, transform $transition-time $transition-type;
	transition-delay: $transition-time * 2;
	opacity: 0;
	transform: translate(0,10px);
	margin-top: 5px;
	font-size: 18px;
	color: lighten($body-color, 50%);

	.active.loaded & {
		opacity: 1;
		transform: translate(0,0);
	}
}

.trans-letters span {
	transition: opacity $transition-time $transition-type, transform $transition-time $transition-type;
	transition-delay: $transition-time;
	transform: translate(10px,0);
	opacity: 0;
	display: inline-block;

	.active.loaded & {
		opacity: 1;
		transform: translate(0,0);
	}

	&:nth-child(1) {
		transition-delay: $transition-time;
	}
	&:nth-child(2) {
		transition-delay: $transition-time * 1.1;
	}
	&:nth-child(3) {
		transition-delay: $transition-time * 1.2;
	}
	&:nth-child(4) {
		transition-delay: $transition-time * 1.3;
	}
	&:nth-child(5) {
		transition-delay: $transition-time * 1.4;
	}
	&:nth-child(6) {
		transition-delay: $transition-time * 1.5;
	}
	&:nth-child(7) {
		transition-delay: $transition-time * 1.6;
	}
}

.amp {
	position: relative;
	font-size: small;
	top: -3px;
	font-weight: 400;
}

strong {
	position: relative;
	font-weight: 300;
	color: darken($brand-primary, 10%);

	&::after {
		transition: width $transition-time $transition-type, left $transition-time $transition-type;
		transition-delay: $transition-time * 2;
		content: "";
		width: 0;
		position: absolute;
		bottom: 3px;
		left: 100%;
		z-index: -1;
		border-bottom: 1px dotted lighten($gray-light, 40%);

		.waypoint-active & {
			width: 100%;
			left: 0;
		}
	}
}

small {
	color: lighten($body-color, 30%);
	font-size: small;
	letter-spacing: 1px;

	&.display--block {
		margin-top: 10px;
	}
}

.important {
	display: block;
	width: 90px;
	border-radius: 5px;
	color: $red;
	margin: 0 auto 10px;
    font-size: 13px;
    background-color: $white;
    padding: 3px 5px;
    font-weight: 400;
}



.trans-underline {
	position: relative;

	&::before {
		transition: height $transition-time * 2 $transition-type, transform $transition-time * 2 $transition-type;
		transition-delay: $transition-time * 2;
		content: "";
		background-color: lighten($brand-primary, 40%);
		height: 0;
		bottom: 0;
		left: 0;
		right: 0;
		transform: translate(0, 0);
		position: absolute;
		top: 50%;
		z-index: -1;

		.active.loaded & {
			height: 100%;
			transform: translate(0, -50%);
		}
	}

	&::after {
		transition: width $transition-time $transition-type, transform $transition-time $transition-type;
		transition-delay: $transition-time * 2;
		content: "";
		width: 0;
		transform: translate(0, 0);
		position: absolute;
		bottom: -10px;
		left: 50%;
		border-bottom: 1px solid $brand-primary;

		.active.loaded & {
			width: 30px;
			transform: translate(-15px, 0);
		}
	}
}