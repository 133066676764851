// @include media-breakpoint-up(sm) {
// }
// @include media-breakpoint-up(md) {
// }
// @include media-breakpoint-up(lg) {
// }
// @include media-breakpoint-up(xl) {
// }

a:link,
a:active,
a:hover,
a:visited {
	text-decoration: none;
}





.btn--slide-hover {
	position: relative;
	padding-left: 10px;
	padding-right: 10px;
	color: $gray;

	&:after {
		transition: transform $transition-time $transition-type;
		content: "";
		position: absolute;
		background: lighten($brand-primary, 30%);
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transform: scaleX(0);
		transform-origin: right;
		z-index: -1;
		border-radius: 10px;
		overflow: hidden;
	}

	&:hover::after {
		transform: scaleX(1);
		transform-origin: left;
		transition-delay: 0s;
	}
}

.btn--circle {
	overflow: hidden;
	color: $white;
	width: 80px;
	height: 80px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	font-size: 10px;
	border-radius: 100%;
	background: $brand-primary;
	position: relative;

	&::before {
		transition: transform $transition-time $transition-type;
		content: "";
		background: $white;
		transform-origin: 50% 50%;
		transform: scale(0);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: 100%;
		overflow: hidden;
	}

	&::after {
		transition: opacity $transition-time $transition-type, transform $transition-time $transition-type;
		content: attr(title);
		transform: translate(100%, 0);
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		color: $brand-primary;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-transform: uppercase;
	}

	&:hover {

		&::before {
			transform: scale(1);
		}
		&::after {
			transform: translate(0, 0);
			opacity: 1;
		}
	}

	
}


.btn-link--alt {
	@extend .btn--slide-hover;
	color: $white;
	padding-left: 10px;
	padding-right: 10px; 
	border-radius: 10px;

	&:after {
		background: darken($brand-primary, 5%);
	}

	&:hover {
		color: $white;
	}
}