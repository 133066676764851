// @include media-breakpoint-up(sm) {
// }
// @include media-breakpoint-up(md) {
// }
// @include media-breakpoint-up(lg) {
// }
// @include media-breakpoint-up(xl) {
// }


// BY DEFAULT SPACING THINGS OUT A BIT
.full-panel {
	transition: opacity $transition-time ease-in-out;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	opacity: 0;

	@include media-breakpoint-up(lg) {
		height: 100vh;
	}

	.active & {
		opacity: 1; 
	}
}


.content-wrap {
	width: 100%;
	max-width: 100%; /*limit width*/
	position: relative;

	@include media-breakpoint-up(lg) {
		height: 100vh;
	}
}


#panel-info1 .content-block--1 {
	background: $white;
}

.content-block--2 {
	padding: 0 30px;

	@include media-breakpoint-up(md) {
		padding: 0 3%;
	}

	.panel--last & {
		background: $gray!important;
	}
}

.full-panel .content-block--2 {
	background: darken($white, 3%);
}

.content-block--3 {	
	transition: opacity $transition-time * 2 ease-in-out;
	transition-delay: $transition-time * 2;
	opacity: 0;
	z-index: 2;
	color: $white;

	.active.loaded & {
		opacity: 1;
	}

	.page--about &,
	.page--contact & {
		background: $brand-primary;
	}
}


.inner-container {
	margin-top: $navbar-height;
	background: adjust-hue($brand-primary, 5%);	
	@include media-breakpoint-up(lg) {
		top: $page-spacing-top;
		margin-top: 0;
	}
}

// Required on the homepage
#home.inner-container {
	overflow: hidden;
}

// ADD THE BG FONT FOR EACH PAGE
.inner-container::before,
#about .content-block--3::before,
#contact .content-block--3::before {
	transition: opacity $transition-time * 5 ease-out;
	transition-delay: $transition-time * 3;
	content: "";
	opacity: 0;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	line-height: 60vw;
	text-align: center;
	font-weight: 800;
	color: rgba(0,0,0,0.04);
	font-size: 50vw;
	z-index: 2;
	overflow: hidden;

	.active.loaded & {
		opacity: 1;
	}

	@include media-breakpoint-up(lg) {
		top: $page-spacing-top;
		left: $page-spacing-sides;
		right: $page-spacing-sides;
	}
}

#home.inner-container::before {
	content: "'Sup?";
}

#about .content-block--3::before {
	content: "About";
	z-index: 3;
}

#contact .content-block--3::before {
	content: "Contact";
	z-index: 3;
}

.content-block {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 100%;
	min-height: 300px;
	position: relative;
}



// RESPONSIVE FROM LARGE
@include media-breakpoint-up(lg) {

	.barba-container {
		margin: 0 $page-spacing-sides 0;
		background: $white;
		position: relative;
	}

	.page--home.inner-container {
		&::after {
			content: "";
			width: 50%;
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			width: 50%;
			background: $brand-primary;
			z-index: 1;
		}
	}

	// Add Shadows to page sides
	.barba-container::before,
	.barba-container::after {
		content: "";
		position: fixed;
		width: 1px;
		background: transparent;
		top: $page-spacing-top;
		height: 100vh - $page-spacing-top;
	}

	.barba-container::before {
		left: $page-spacing-sides;
		box-shadow: -3px 5px 10px rgba(0,0,0,0.2);
	}

	.barba-container::after {
		right: $page-spacing-sides;
		box-shadow: 3px 5px 10px rgba(0,0,0,0.2);

	}


	.content-block {
		position: absolute;
		background: transparent;
	}

	.content-block--1 {
		top: 0;
		left: 0;
		width: 50%; // percent-based because Chrome gets confused with the scrollbar
		height: 50vh;

		// If it's the first one, show it above the rest
		#panel-info1 & {
			z-index: $zindex-dropdown-backdrop;
			border-bottom: 1px solid $border-color;
			border-right: 1px solid $white; // required to cover up underneath overlap
		}

		// When Waypoint is active
		.waypoint-active & {
			position: fixed;
			top: $page-spacing-top;
			height: 50vh - ($page-spacing-top / 1.5);
			width: 50% - $page-spacing-sides;
			left: $page-spacing-sides;
		}
	}

	.content-block--2 {
		bottom: 0;
		left: 0;
		width: 50%;
		height: 50vh;
		z-index: 2;

				// If it's the first one, show it above the rest
		.page--about #panel-info1 &,
		.page--contact #panel-info1 & {
			z-index: $zindex-dropdown-backdrop;
		}

		// When Waypoint is active
		.page--about .waypoint-active &,
		.page--contact .waypoint-active & {
			position: fixed;
			bottom: 0;
			height: 50vh;
			width: 50% - $page-spacing-sides;
			left: $page-spacing-sides;
		}
	}

	.page--about .content-block--3,
	.page--contact .content-block--3 {
		height: auto;
		bottom: initial;
	}

	.content-block--3,
	.skin-float {
		bottom: 0;
		top: $page-spacing-top;
		right: 0;
		width: 50%; // percent-based because Chrome gets confused with the scrollbar
		height: 100vh;
	}

	// When Waypoint is active
	.skin-float {
		.page--home .waypoint-active & {
			position: fixed;
			top: $page-spacing-top;
			width: 50% - $page-spacing-sides;
			right: $page-spacing-sides;
		}
	}
	
}


.skin-float {
	transition: opacity $transition-time * 3 ease-out;
	transition-delay: $transition-time;
	opacity: 0;
	display: flex;
	flex-direction: column;
	align-items: stretch;

	justify-content: center;
	background-size: contain!important;
	width: 100%;
	position: absolute;
	top: 10px;
	left: 0;
	right: 0;
	bottom: 20px;

	@include media-breakpoint-up(lg) {
		background-size: inherit!important;
		align-items: center;
		position: relative;
		top: initial;
		left: initial;
		right: initial;
		bottom: initial;
	}

	.active.loaded & {
		opacity: 1;
	}

	.panel--info1 & {
		background: url(../images/skin-layer1.png) no-repeat center;
	}
	.panel--info2 & {
		background: url(../images/skin-layer2.png) no-repeat center;
	}
	.panel--info3 & {
		background: url(../images/skin-layer3.png) no-repeat center;
	}
	.panel--info4 & {
		background: url(../images/skin-layer4.png) no-repeat center;
	}
	.panel--info5 & {
		background: url(../images/skin-layer5.png) no-repeat center;
	}
	.panel--info6 & {
		background: url(../images/skin-layer-final.png) no-repeat center;
	}
	
	@include media-breakpoint-up(lg) {
		.panel--info6 & {			
			background: url(../images/skin-layer6.png) no-repeat center;
		}
	}

	.panel--info7 & {
		background: url(../images/skin-layer7.png) no-repeat center;
	}
	
	
}


.content--lift {	
	position: relative;
	z-index: 4;
	width: 100%;
	flex-direction: column;
	align-items: center;
    justify-content: center;
    display: flex;
}


.block--header {
	display: block;
	margin: 50px 30px 30px;

	img {
		transition: filter $transition-time ease-in-out;
		border-radius: 100%;
		max-width: 125px;
		filter: invert(0deg);
	    box-shadow: 0 0 0 10px darken($brand-primary, 5%);

	    &:hover {
			filter: hue-rotate(90deg);
	    }
	}
}


.block--list {
	display: block;
	border-bottom: 1px solid darken($brand-primary, 5%);
	padding-top: 30px;
	padding-bottom: 30px;
}

.block--list h3,
.block--text h3 {
	background: darken($brand-primary, 10%);
	padding: 5px 10px;
	font-size: 17px;
	border-radius: 20px;
	display: inline-block;
}

.block--text h3 {
	margin-bottom: 20px;
}

.block--text {
	font-size: 14px;
	margin-top: 7px;
	margin-bottom: 7px;
	max-width: 450px;
	margin-left: 20px;
	margin-right: 20px;

	> p:first-of-type {
		font-size: 22px;
		line-height: 1.4;
	}
}

.block--list ul {
	display: block;
	text-align: left;
	margin: 10px 10px;
	padding: 0;
}

.block--list ul li {
	list-style-type: none;
	text-align: center;
	font-size: 14px;
	margin-bottom: 7px;
}

.block--list ul li span {
	display: block;
	text-transform: uppercase;
	font-size: 11px;
	color: darken($brand-primary, 20%);
	margin-top: 10px;
}


.block--last {
	background: $gray;
	padding: 30px;
	width: 100%;
	margin-top: 30px;
	display: flex;
    align-items: center;
    justify-content: center;
}