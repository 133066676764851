// @include media-breakpoint-up(sm) {
// }
// @include media-breakpoint-up(md) {
// }
// @include media-breakpoint-up(lg) {
// }
// @include media-breakpoint-up(xl) {
// }


.navbar {
	background-color: $body-bg;
	height: $navbar-height;
	padding: 0 $page-spacing-sides;
	font-size: 15px;
	display: flex;
	align-items: center;
	flex-direction: row;
	flex-wrap: nowrap;

	@include media-breakpoint-up(lg) {
		height: $page-spacing-top;
	}
}

.navbar-brand {
	padding: 0;
	font-size: 15px;
}

.navbar-nav {
	align-self: flex-end;
	text-transform: lowercase;
	flex-direction: row;

	.nav-contact {
		margin-left: 10px;
	}
}

.navbar-toggleable-sm .navbar-collapse {
	@include media-breakpoint-up(md) {
		width: auto;
	}
}

.navbar .nav-link {
	padding: 3px 8px!important;
	display: block;
	line-height: 1;
	border-radius: 10px;
}

// CURRENT NAV PAGE
.navbar.nav-cur--home .navbar-brand,
.navbar.nav-cur--about .nav-about,
.navbar.nav-cur--contact .nav-contact {
	position: relative;

	&::before {
		content: "";
		position: absolute;
		left: 50%;
		bottom: -12px;
		background-color: $brand-primary;
		width: 30px;
		height: 2px;
		margin-left: -15px;
		opacity: 0.5;
	}
}

.navbar-brand h1 {
	font-size: 15px;
	margin: 0;
	line-height: 1;
	font-weight: 400;
}

#sub-nav {
	overflow: hidden;
	position: absolute;
	bottom: 30px;

	.nav-link.active {
		background-color: $brand-primary;
		color: $white;
	}

	&::before {		
		transition: transform $transition-time * 3 $transition-type;
		transition-delay: $transition-time * 2;
		content: "";
		height: 1px;
		background-color: $brand-primary;
		width: 100%;
		transform: translate(-100%, 0);
		position: absolute;
    	top: 0;

		.active.loaded & {
			transform: translate(100%, 0);
		}
	}
}


#sub-nav .nav-item {
	border-top: 1px dotted $border-color;
}

#sub-nav .nav-link {
	transition: color $transition-time / 2 ease-out;
	width: 25px;
	height: 25px;
	line-height: 25px;
	display: inline-block;
	padding: 0;
	margin: 10px 3px;
	font-size: 12px;
	font-weight: 300;
	border-radius: 100%;
	text-transform: uppercase;
	color: lighten($gray-light, 15%);
	text-align: center;
	position: relative;

	&:hover {
		color: $white;
	}

	&::after {
		transition: transform $transition-time $transition-type;
		content: "";
		position: absolute;
		background: lighten($brand-primary, 25%);
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 100%;
		transform: scale(0);
  		transform-origin: right;
  		z-index: -1;
	}

	&:hover::after {
		transform: scale(1);
		transform-origin: left;
		transition-delay: 0s;
	}
}